<template>
  <div class="gPage">
    <el-container style="height: 100%">
      <el-aside width="100px">
        <div id="stencil">
          <div class="title">基础节点</div>
          <div class="dnd-rect" @mousedown="startDragToGraph({shape:'start round mix'},$event)"><i
              class="el-icon-video-play"></i>开始
          </div>
          <div class="dnd-rect" @mousedown="startDragToGraph({shape:'node'},$event)"><i
              class="el-icon-setting"></i>任务节点
          </div>
          <div class="dnd-rect" @mousedown="startDragToGraph({shape:'end round'},$event)"><i
              class="el-icon-video-pause"></i>结束
          </div>
          <div @click="reset" class="dnd-reset" slot="reference"><i class="el-icon-refresh-right"></i>重新绘制</div>
        </div>
      </el-aside>
      <el-main>
        <div id="graph" ref="graphContainer"></div>
      </el-main>
      <el-aside v-if="currentNode || currentEdge" width="200px">
        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 10px;overflow-x: hidden">
          <el-tab-pane label="节点属性" name="1">
            <div class="tabPan">
              <el-form :model="editNode" :inline="false">
                <el-form-item label="节点" size="small">
                  <el-input disabled v-model="currentNode.id" :title="currentNode.id"></el-input>
                </el-form-item>
                <el-form-item required label="节点名称" prop="label" size="small">
                  <el-input v-model="editNode.label" @input="inputNode"></el-input>
                </el-form-item>
                <el-form-item label="审批用户" prop="users" size="small">
                  <el-select v-model="editNode.users" multiple collapse-tags filterable @change="changeUser">
                    <el-option v-for="item in teacherList" :key="item.Guid" :value="item.Guid"
                               :label="item.Name"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="抄送用户" prop="csusers" size="small">
                  <el-select v-model="editNode.csusers" multiple collapse-tags filterable @change="changeCsuser">
                    <el-option v-for="item in teacherList" :key="item.Guid" :value="item.Guid"
                               :label="item.Name"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="审批条件用户" prop="tjusers" size="small">
                  <el-select v-model="editNode.tjusers" multiple collapse-tags filterable @change="changeTjuser">
                    <el-option v-for="item in teacherList" :key="item.Guid" :value="item.Guid"
                               :label="item.Name"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="是否会签" prop="ishq" size="small">
                  <el-select v-model="editNode.ishq" @change="changeHq">
                    <el-option :value="0" label="否"></el-option>
                    <el-option :value="1" label="是"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="连线属性" name="2">
            <div class="tabPan">
              <el-form :data="editEdge" :inline="false">
                <el-form-item label="源节点" size="small">
                  <el-input disabled v-model="editEdge.source" :title="editEdge.source"></el-input>
                </el-form-item>
                <el-form-item label="目标节点" size="small">
                  <el-input disabled v-model="editEdge.target" :title="editEdge.target"></el-input>
                </el-form-item>
                <el-form-item label="文本" prop="label" size="small">
                  <el-input v-model="editEdge.label"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import {Graph} from '@antv/x6'
import '@antv/x6-vue-shape'
// 插件 键盘监听事件
import {Keyboard} from '@antv/x6-plugin-keyboard'
// 拖拽事件
import {Dnd} from '@antv/x6-plugin-dnd'

import {Stencil} from '@antv/x6-plugin-stencil'
import {Transform} from '@antv/x6-plugin-transform'
import {Selection} from '@antv/x6-plugin-selection'
import {Snapline} from '@antv/x6-plugin-snapline'
import {Clipboard} from '@antv/x6-plugin-clipboard'
import {History} from '@antv/x6-plugin-history'
import {register} from '@antv/x6-vue-shape'
import insertCss from 'insert-css'
import Swal from "sweetalert2";

export default {
  name: 'MindMap',
  props: ['teacherList', 'schemeContent'],
  data() {
    return {
      activeName: '1',
      graphOut: {},
      currentNode: '',
      editNode: {label:'',users:[],csusers:[],tjusers:[],ishq:''},
      currentEdge: '',
      editEdge: {label: '', source: '', target: ''},
      dnd: {},
      // 节点形状
      typesList: [],
      // 连接桩
      ports: {
        groups: {
          top: {
            position: 'top',
            attrs: {
              circle: {
                magnet: true,
                stroke: 'black',
                r: 4,
              },
            },
          },
          bottom: {
            position: 'bottom',
            attrs: {
              circle: {
                magnet: true,
                stroke: 'black',
                r: 4,
              },
            },
          },
          left: {
            position: 'left',
            attrs: {
              circle: {
                magnet: true,
                stroke: 'black',
                r: 4,
              },
            },
          },
          right: {
            position: 'right',
            attrs: {
              circle: {
                magnet: true,
                stroke: 'black',
                r: 4,
              },
            },
          },
        },
      },
      datas: {users: [], csusers: [], tjusers: [], ishq: '', roles: [], orgs: [], NodeDesignate: "SPECIAL_USER"},
    }
  },

  mounted() {
    this.graphOut = this.initGraph()
    if (this.schemeContent && this.graphOut) {
      this.graphOut.fromJSON(JSON.parse(this.schemeContent))
    }
  },
  methods: {
    inputNode(e) {
      this.currentNode.attr('text/text', e)
    },

    changeUser(users) {
      if (!this.currentNode.data) {
        this.currentNode.data = {users}
      } else {
        this.currentNode.data.users = users
      }
    },

    changeCsuser(csusers) {
      if (!this.currentNode.data) {
        this.currentNode.data = {csusers}
      } else {
        this.currentNode.data.csusers = csusers
      }
    },

    changeTjuser(tjusers) {
      if (!this.currentNode.data) {
        this.currentNode.data = {tjusers}
      } else {
        this.currentNode.data.tjusers = tjusers
      }
    },

    changeHq(ishq) {
      if (!this.currentNode.data) {
        this.currentNode.data = {ishq}
      } else {
        this.currentNode.data.ishq = ishq
      }
    },

    //初始化
    initGraph() {
    const graph = new Graph({
        container: this.$refs.graphContainer,
        autoResize: true, // 大小自适应
        background: {
          color: '#F2F7FA',
        },
        //网格
        grid: {
          visible: true,
          type: 'doubleMesh',
          args: [
            {
              color: '#eee', // 主网格线颜色
              thickness: 1, // 主网格线宽度
            },
            {
              color: '#ddd', // 次网格线颜色
              thickness: 1, // 次网格线宽度
              factor: 2, // 主次网格线间隔
            },
          ],
        },
        magnetThreshold: 'onleave',
        //平移
        panning: true,
        // 缩放
        mousewheel: true,
        // 开启自动吸附
        connecting: {
          // 距离节点或者连接桩 50 px 触发自动吸附
          snap: true,
          // 是否允许连接到画布空白位置的点
          allowBlank: false,
          // 是否允许创建循环连线
          allowLoop: false,
          // 拖动边时,是否高亮显示所有可用连接桩或节点
          highlight: true,
        },
        modes: {
          default: ['drag-node']
        },
        connector: {
          name: 'rounded',
          args: {
            radius: 8
          }
        }

      })

      // 支持拖拽
      this.dnd = new Dnd({
        target: graph,
        scaled: false,
      })

      //自定义开始节点
      Graph.registerNode('start round mix', {
        inherit: 'rect',
        ports: {
          ...this.ports,
          items: [{
            id: 'port_3',
            group: 'bottom',
          }]
        },
        width: 100,
        height: 32,
        label: '开始',
        data: this.datas,
        attrs: {
          body: {
            stroke: '#8f8f8f',
            strokeWidth: 1,
            fill: '#fff',
            rx: 16,
            ry: 16,
          },
          text: {text: '213'}
        },
      }, true,)

      //自定义结束节点
      Graph.registerNode('end round', {
        inherit: 'rect',
        ports: {
          ...this.ports,
          items: [{
            id: 'port_1',
            group: 'top',
          }]
        },
        width: 100,
        height: 32,
        label: '结束',
        data: this.datas,
        attrs: {
          body: {
            stroke: '#8f8f8f',
            strokeWidth: 1,
            fill: '#fff',
            rx: 16,
            ry: 16,
          },
          text: {text: ''}
        },
      }, true,)

      //任务节点
      Graph.registerNode('node', {
        inherit: 'rect',
        ports: {
          ...this.ports,
          items: [{
            id: 'port_1',
            group: 'top',
          }, {
            id: 'port_3',
            group: 'bottom',
          }]
        },
        width: 120,
        height: 32,
        label: '任务节点',
        data: this.datas,
        attrs: {
          body: {
            stroke: '#8f8f8f',
            strokeWidth: 1,
            fill: '#fff',
            rx: 4,
            ry: 4,
          },
          text: {text: ''}
        },
      }, true,)

      // 添加 plugin 插件
      graph.use(new Keyboard()) //键盘事件
          .use(new Selection({
            enabled: true,
            multiple: true,
            rubberband: true,
            movable: true,
            showEdgeSelectionBox: true,
            showNodeSelectionBox: true,
            pointerEvents: 'none'
          })) // 绑定框选
          .use(new Snapline({
            enabled: true,
            sharp: true,
          })) // 对齐线
          .use(new Clipboard()) // 剪贴板
          .use(new History({enabled: true})) // 绑定撤销
          .use(new Transform({
            resizing: {
              enabled: true,
              minWidth: 1,
              maxWidth: 200,
              minHeight: 1,
              maxHeight: 150,
              orthogonal: false,
              restrict: false,
              preserveAspectRatio: false,
            },
            rotating: {
              enabled: true,
              grid: 15,
            },
          }),)

      // 鼠标事件
      this.mouseEvent(graph)

      // 键盘事件
      this.keyboardEvent(graph)

      // 添加子节点的逻辑...
      return graph
    },

    // 鼠标事件
    mouseEvent(graph) {
      graph.on('node:click', ({node}) => {
        this.activeName = '1'
        this.updateNode(node)
      })

      // 鼠标 Hover 时添加删除按钮
      graph.on('node:mouseenter', ({node}) => {
        node.addTools({
          name: 'button-remove',
        })
      })

      // 鼠标移开时删除所有的工具
      graph.on('node:mouseleave', ({node}) => {
        node.removeTools() // 删除所有的工具
      })

      //连接的线
      graph.on('edge:click', ({e, x, y, edge, view}) => {
        this.activeName = '2'
        this.updateEdge(edge)
      })

      graph.on('edge:mouseenter', ({cell}) => {
        cell.addTools([
          {name: 'vertices'},
          {
            name: 'button-remove',
            args: {},
          },
        ])
      })

      graph.on('edge:mouseleave', ({edge}) => {
        edge.removeTools() // 删除所有的工具
      })
    },

    // 键盘事件
    keyboardEvent(graph) {
      // 键盘事件
      graph.bindKey('tab', (e) => {
        e.preventDefault()
        const selectedNodes = graph.getCells().filter((item) => item.isNode())
        if (selectedNodes.length) {
          const node = selectedNodes[0]
          const type = node.attrs['type']
          this.addChildNode(node.id, type)
        }
      })

      graph.bindKey('delete', (e) => {
        this.removeNode(this.currentNode)
      })

      graph.bindKey('backspace', (e) => {
        this.removeNode(this.currentNode)
      })
    },

    //拖拽生成节点
    startDragToGraph({shape}, e) {
      let dragNode = this.graphOut.createNode({
        shape,
      })
      this.dnd.start(dragNode, e)
    },

    //添加节点
    addChildNode(nodeId, type) {
      console.log(nodeId, type)
    },

    // 删除节点
    removeNode(node) {
      this.graphOut.removeNode(node)
    },

    //修改节点
    updateNode(node) {
      this.currentNode = node
      this.editNode.label = node.label
      this.editNode.users = node.data ? node.data.users : ''
      this.editNode.csusers = node.data ? node.data.csusers : ''
      this.editNode.tjusers = node.data ? node.data.tjusers : ''
      this.editNode.ishq = node.data ? node.data.ishq : ''
    },

    //修改边
    updateEdge(edge) {
      this.currentEdge = edge
      this.editEdge.source = edge.source ? edge.source.cell : ''
      this.editEdge.target = edge.target ? edge.target.cell : ''
    },

    //重新绘制
    reset() {
      let self = this;
      Swal.fire({
        text: "确认要重新绘制吗？",
        icon: "question",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        showCancelButton: true,
      }).then((v) => {
        if (v.isConfirmed) {
          self.graphOut = self.initGraph()
        }
      })
    },

    handleClick(e) {

    },
//获取数据
//     getMapDetail() {
//       loading.value = true;
//       getNodeDetail({knwlgMapId: route.query.pkId})
//           .then(({data: cells}) => {
//             graph.fromJSON({cells});
//             loading.value = false;
//           })
//           .catch(() => {
//             loading.value = false;
//           });
//     }
//     ,
  },
}
</script>

<style>

.gPage {
  height: 60vh;
}

.el-main {
  padding: 0;
}

/* 样式调整 */
.title {
  width: 64px;
  height: 22px;
  background: #0076c8;
  color: #fff;
  line-height: 22px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
}

#stencil {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dfe3e8;
  font-size: 12px;
}

.dnd-rect {
  width: 90px;
  height: 30px;
  line-height: 30px;
  /*text-align: center;*/
  background: #f4f6fc;
  /*border-radius: 6px;*/
  cursor: move;
  font-size: 15px;
  margin-top: 12px;
  padding: 0 5px;
}

.dnd-rect:hover {
  border: 1px dashed #409eff;
  color: #409eff;
}

.dnd-reset {
  width: 80px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-size: 13px;
  padding: 0 5px;
  border: 1px solid #dcdfe6;
  margin-top: 30px;
}

.dnd-reset:hover {
  background: #e6f1fa;
  border: 1px solid #b3d6ef;
  color: #0076c8;
}

.dnd-polygon {
  width: 35px;
  height: 35px;
  border: 2px solid #000000;
  transform: rotate(45deg);
  cursor: move;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.dnd-circle {
  width: 35px;
  height: 35px;
  line-height: 45px;
  text-align: center;
  border: 5px solid #000000;
  border-radius: 100%;
  cursor: move;
  font-size: 12px;
  margin-top: 30px;
}

.dnd-start {
  border: 2px solid #000000;
}

.x6-widget-stencil {
  background-color: #f8f9fb;
}

.x6-widget-stencil-title {
  background: #eee;
  font-size: 1rem;
}

.x6-widget-stencil-group-title {
  font-size: 1rem !important;
  background-color: #fff !important;
  height: 40px !important;
}

.x6-widget-transform {
  margin: -1px 0 0 -1px;
  padding: 0px;
  border: 1px solid #239edd;
}

.x6-widget-transform > div {
  border: 1px solid #239edd;
}

.x6-widget-transform > div:hover {
  background-color: #3dafe4;
}

.x6-widget-transform-active-handle {
  background-color: #3dafe4;
}

.x6-widget-transform-resize {
  border-radius: 0;
}

.el-dialog__body {
  padding: 0 20px;
}

.tabPan {
  /*overflow: auto;*/
}

.tabPan .nodeTitle {
  font-size: 12px;
  color: #aaaaaa;
  text-align: center;
}
</style>
