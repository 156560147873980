<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 流程设计</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="btns"><span @click="$router.back()" class="btn-back">返回 ></span></div>
      <el-steps :active="activeIndex" finish-status="success" simple style="margin: 20px;background: #fff;">
        <el-step title="基本信息"></el-step>
        <el-step title="流程设计"></el-step>
      </el-steps>
      <div v-if="activeIndex==0" class="content c1">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="流程类型">
            <el-select v-model="form.SchemeType" @change="changeType">
              <el-option v-for="item in config.type" :value="item.Key" :label="item.Value"
                         :key="item.Key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.SchemeName"></el-input>
          </el-form-item>
          <el-form-item label="流程编号">
            <el-input v-model="form.SchemeCode"></el-input>
          </el-form-item>
          <el-form-item label="发布时间">
            <el-date-picker v-model="form.CreateTime" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="摘要">
            <el-input type="textarea" v-model="form.Description" :rows="5"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeIndex==1" class="content">
        <graph ref="graphNode" :teacherList="teacherList" :schemeContent="form.SchemeContent"></graph>
      </div>
      <div slot="footer" align="center">
        <el-button v-if="activeIndex==0" @click="next">下一步</el-button>
        <template v-if="activeIndex==1">
          <el-button @click="activeIndex--">上一步</el-button>
          <el-button @click="save" type="success">保存</el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import graph from '@/components/graph'

export default {
  name: "edit",
  components: {graph},
  data() {
    return {
      id: this.$route.query.id || '',
      sta: {
        show: false,
        title: '添加',
      },
      activeIndex: 0,
      form: {
        SchemeCode: new Date().getTime(),
        CreateTime:new Date(),
        SchemeVersion:1,
        SchoolId:JSON.parse(localStorage.getItem('userInfo')).SCH_ID,
        SchemeCanUser:'test',
        AuthorizeType:2,
        StartUserType:2,
      },
      schemeType:'',
      config: {},
      teacherList:[],
    }
  },
  mounted() {
    this.getConfig()
    this.getTeachers()
    if(this.id){
      this.getDetail()
    }
  },
  methods: {
    getDetail(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/scheme/detail?id="+this.id,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.form = AppendData
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    getConfig() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/scheme/config",
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.config = AppendData
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    getTeachers() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/DOC/PowerApi/GetTeachers",
        finally() { self.sta.loading = false; },
        completed: function (res) {
          self.sta.show = false;
          self.teacherList = res.DATA
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    changeType(val){
      let item = this.config.type.find(v=>v.Key==val)
      this.form.SchemeType = item.Key
      this.form.SchemeName = item.Value
      this.form.DbName = item.Key
    },
    next(){
      if(!this.form.SchemeType){
        this.$message.warning('请选择流程')
        return
      }
      if(!this.form.SchemeName){
        this.$message.warning('请输入标题')
        return
      }
      this.activeIndex++
    },
    save() {
      let schemeContent = this.$refs.graphNode.graphOut.toJSON()
      this.form.SchemeContent = JSON.stringify(schemeContent)
      console.log(schemeContent)
      if (this.id) {
        this.update()
      } else {
        this.add()
      }
    },
    update() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/scheme/update",
        data: {id:self.id,...self.form},
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    add() {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/scheme/add",
        data: self.form,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.wrap {
  background: #fff;
  margin: 0 20px;
  padding-bottom: 20px;
  box-sizing: border-box
}

.wrap .btns {
  padding: 10px 20px 0;
  text-align: right;
}

.wrap .btns .btn-back {
  cursor: pointer;
}

.content {
  min-height: 60vh;
}

.content.c1 {
  width: 50%;
  margin: 0 auto;
}
</style>